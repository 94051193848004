import { graphql } from 'gatsby'
import React, { useMemo } from 'react'

import { JobRolePageContent } from '../../@import-types/contentful/JobRolePage.types'
import { Office } from '../../@import-types/contentful/Office.types'
import ContentArea from '../../components/ContentArea'
import Layout from '../../components/Layout'
import { ApplyToJobBlockTypename } from '../../components/_blocks/ApplyToJobFormBlock/ApplyToJobFormBlock'
import JobHero from '../../components/_heroes/JobHero'
import JobTeasersGrid from '../../components/_layouts/JobTeasersGrid'
import Colors from '../../constants/Colors'
import { RootPageQuery } from '../../graphql/global.graphql'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import { renderTemplateString } from '../../utils/stringUtils'

import styles from './job-role-page.module.scss'

type JobRolePageProps = RootPageQuery<{
  jobRolePage: JobRolePageContent
  office?: Office | null
  otherJobs?: { edges: Array<{ node: JobRolePageContent }> } | null
}>

export default function JobRolePage({ data, pageContext }: JobRolePageProps) {
  const { jobRolePage, office, otherJobs, commonContent } = data
  const { title, content } = jobRolePage

  const similarPositionsLabel = useMicroCopy(
    'text.similar_job_positions',
    commonContent
  )

  const otherJobNodes = useMemo(
    () => otherJobs?.edges.map(({ node }) => node) ?? [],
    [otherJobs]
  )

  const hasApplyToJobFormOnPage = useMemo(() => {
    if (!content) {
      return false
    }

    return content.some((item) => item.__typename === ApplyToJobBlockTypename)
  }, [content])

  return (
    <Layout
      data={data}
      basePageContent={jobRolePage}
      pageContext={pageContext}
      pageTextColor={Colors.GreenDark}
      noFooter={hasApplyToJobFormOnPage}
    >
      <JobHero title={title} subtitle={office?.city} />

      {content && (
        <div className={styles.content}>
          <ContentArea content={content} />

          {otherJobNodes.length > 0 && (
            <JobTeasersGrid
              title={renderTemplateString(similarPositionsLabel, {
                office: office?.city,
              })}
              jobs={otherJobNodes}
            />
          )}
        </div>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query JobRolePageById(
    $id: String!
    $officeId: String
    $startPageId: String!
    $locale: String!
  ) {
    jobRolePage: contentfulJobRolePage(id: { eq: $id }) {
      ...BasePageFragment

      title

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }
    }

    office: contentfulOffice(id: { eq: $officeId }) {
      city
    }

    otherJobs: allContentfulJobRolePage(
      filter: { office: { id: { eq: $officeId } }, id: { ne: $id } }
      sort: { fields: applyBeforeDate, order: ASC }
    ) {
      edges {
        node {
          ...JobRolePageTeaserFragment
        }
      }
    }

    ...GlobalFragment
  }
`
