import classNames from 'classnames'
import React from 'react'

import { ContactFormId, CTATargetId } from '../../../constants/GlobalDOM'
import useMicroCopy from '../../../hooks/globalContent/useMicroCopy'
import useJoinUsPageLink from '../../../hooks/routing/useJoinUsPageLink'
import useIsDesktop from '../../../hooks/useIsDesktop'
import {
  getSmoothScrollToElement,
  getSmoothScrollToHash,
} from '../../../hooks/useSmoothScrollToWindowHashOnLoad'
import {
  animateScrollToCenter,
  animateScrollToWithMargin,
} from '../../../utils/scrollUtils'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import CTAButton from '../../_elements/_buttons/CTAButton'
import LayoutContainer from '../../_layouts/LayoutContainer'
import SvgArrowRightSmall from '../../_svg/SvgArrowRightSmall'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './job-hero.module.scss'

interface JobHeroProps {
  title: string | null | undefined
  subtitle?: string | null

  className?: string
}

export default function JobHero({
  title,
  subtitle,

  className,
}: JobHeroProps) {
  const isDesktop = useIsDesktop()

  const backToCareersLabel = useMicroCopy('text.back_to_careers')
  const applyLabelMobile = useMicroCopy('text.apply')
  const applyLabelDesktop = useMicroCopy('text.apply_now')

  const backToCareersLink = useJoinUsPageLink()

  function onCTAClick() {
    const ctaTargetElement = document.querySelector<HTMLElement>(
      `#${CTATargetId}`
    )

    if (ctaTargetElement) {
      animateScrollToCenter(ctaTargetElement)
      return
    }

    const contactFormElement = getSmoothScrollToElement(
      getSmoothScrollToHash(ContactFormId)
    )
    if (contactFormElement) {
      animateScrollToWithMargin(contactFormElement)
    }
  }

  return (
    <LayoutContainer className={classNames(styles.container, className)}>
      <InternalLink
        className={styles.backToCareersLink}
        to={backToCareersLink}
        segmentdata={{
          anchor_text: backToCareersLabel,
          position: 'body',
          url: backToCareersLink,
        }}
      >
        <SvgArrowRightSmall className={styles.backToCareersIcon} />
        <span className={styles.backToCareersLabel}>{backToCareersLabel}</span>
      </InternalLink>

      <HeroHeading
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}

      <div className={styles.ctaContainer}>
        <CTAButton
          className={styles.ctaButton}
          isSmaller={!isDesktop}
          onClick={onCTAClick}
          withShadow
        >
          {isDesktop ? applyLabelDesktop : applyLabelMobile}
        </CTAButton>
      </div>
    </LayoutContainer>
  )
}
